﻿* {
	font-family: Roboto, sans-serif;
}

.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;	/* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;

	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;

	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;

	/* Support for IE. */
	font-feature-settings: 'liga';
}

@font-face {
	font-family: 'Roboto';
	src: local('Roboto-Light');
	src: url('../fonts/Roboto-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: local('Roboto-Regular');
	src: url('../fonts/Roboto-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: local('Roboto-Medium');
	src: url('../fonts/Roboto-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: local('Roboto-Bold');
	src: url('../fonts/Roboto-Bold.ttf') format('truetype');
	font-weight: 700;
	font-stretch: normal;
}

@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/MaterialIcons-Regular.ttf') format('truetype');
}