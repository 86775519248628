﻿@import './_variables.scss';
@import './_font.scss';
@import '@material/top-app-bar/mdc-top-app-bar';
@import '@material/elevation/mdc-elevation';
@import '@material/typography/mdc-typography';
@import '@material/textfield/mdc-text-field';
@import '@material/notched-outline/mdc-notched-outline';
@import '@material/card/mdc-card';
@import '@material/button/mdc-button';
@import '@material/layout-grid/mdc-layout-grid';
@import '@material/snackbar/mdc-snackbar';

html {
	background: url("../../Content/Images/ix_background.png") no-repeat center center fixed;
	background-size: cover;
}

html, body {
	height: 100%;
	font-size: 100%;
}

body {
	font-family: 'Roboto';
	margin: 0;
	padding-top: 0.1px; // Prevent header h1 from pushing body down
	background-color: transparent !important;
}

.ix-top-app-bar {
	@include mdc-top-app-bar-fill-color(primary);
	@include mdc-elevation(4);
}

.ix-card {
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
	// height: 432px;
	width: 768px;
	// PORTRAIT
	@media screen and (orientation: portrait) {
		height: auto;
		// PHONES
		@media (max-width: 320px) {
			top: unset;
			left: unset;
			transform: unset;
			width: 100%;
			width: -webkit-fill-available;
			border-radius: unset;
			margin-top: 56px;
		}

		@media (min-width: 321px) and (max-width: 360px) {
			width: 300px;
		}

		@media (min-width: 361px) and (max-width: 400px) {
			width: 340px;
		}

		@media (min-width: 401px) and (max-width: 480px) {
			width: 380px;
			height: auto;
		}

		@media (min-width: 481px) and (max-width: 600px) {
			width: 430px;
		}
		// TABLETS
		@media (min-width: 601px) and (max-width: 840px) {
			width: 430px;
		}
	}
	// LANDSCAPE PHONES
	@media screen and (orientation: landscape) and (max-width: 480px) {
		width: 440px;
		height: auto;
	}

	@media screen and (orientation: landscape) and (min-width: 481px) and (max-width: 600px) {
		width: 460px;
		height: auto;
	}

	@media screen and (orientation: landscape) and (min-width: 601px) and (max-width: 720px) {
		width: 580px;
		height: auto;
	}

	@media screen and (orientation: landscape) and (min-width: 721px) and (max-width: 840px) {
		width: 700px;
		height: auto;
	}
}

/////////////////////////////
// CardSecondary Buttons Start
/////////////////////////////

.secondaryCard-buttons {
	text-align: center;
}

.secondaryCard-buttons button {
	width: 75%;
}

/////////////////////////////
// CardSecondary Buttons End
/////////////////////////////

.ix-card--secondary {
	background: whitesmoke;

	@media screen and (orientation: portrait) {
		border-radius: 0 0 4px 4px;

		@media (max-width: 360px) {
			border-radius: unset;
		}
	}

	@media screen and (orientation: landscape) {
		border-radius: 0 4px 4px 0;
	}
}

.ix-brand {
	height: 60px;

	@media screen and (orientation: portrait) and (max-width: 480px) {
		height: 40px;
	}

	@media screen and (orientation: landscape) and (max-width: 840px) {
		height: 45px;
	}
}

// for use only on outer elements for background
.ix-layout-grid {
	height: inherit;
	padding: unset;
}

// for use only on outer elements for background
.ix-layout-grid__inner {
	height: 100%;
	// grid-gap: unset;
}

.ix-layout-grid__cell--align-center {
	margin: 0 auto;
}

.novarad-brand {
	display: none;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0%);
	// portrait tablet
	@media screen and (orientation: portrait) and (min-width: 720px) {
		display: block;
		bottom: 5%;
	}
	// landscape tablet
	@media screen and (orientation: landscape) and (min-width: 960px) {
		display: block;
		bottom: 3%;
	}
}


.copyright {
	display: none;

	@media screen and (min-width: 600px) {
		display: block;
		font-size: small;
		position: absolute;
		bottom: -30px;
		right: 0px;
	}
}

@mixin countdown {
	height: 32px;
	width: 32px;
}

svg {
	@include countdown;
	position: absolute;
	top: 0;
	right: 0;
	transform: rotateY(-180deg) rotateZ(-90deg);
}

.svg-shortcode {
	height: 131px !important;
	padding-left: 134px !important;
	background: url('../../Content/Images/ix icons/shortcode_icon.svg');
	background-repeat: no-repeat;
}
